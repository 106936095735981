import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Spin, Layout, Card, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import * as facilitiesSlice from '../features/facilities/facilitiesSlice'; 
import FacilitiesTable from './FacilitiesTable';

const { Content } = Layout;

const SearchBar = ({ onSearch }) => {
  return (
    <Input.Search
      placeholder="Search"
      onChange={e => onSearch(e.target.value)}
      enterButton
      style={{ marginBottom: 16, width: '100%' }}
    />
  );
};

const FacilitiesComponent = () => {
  const dispatch = useDispatch();
  const facilities = useSelector(state => state.facilities.facilities);
  const isLoading = useSelector(state => state.loading.isLoading);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (facilities?.length === 0) {
      dispatch(facilitiesSlice.fetchFacilities());
    }
  }, [facilities, dispatch]);

  const filteredFacilities = facilities?.filter(record =>
    Object.values(record).some(item =>
      item ? item.toString().toLowerCase().includes(searchText.toLowerCase()) : ''
    )
  );

  return (
    <Layout style={{ padding: '16px', background: '#f0f2f5' }}>
      <Content>
        <Row justify="center">
          <Col span={24}> {/* Full width for all screen sizes */}
            <Card style={{ marginBottom: 16 }}>
              <SearchBar onSearch={value => setSearchText(value)} />
            </Card>
            <Card>
              {isLoading ? (
                <Spin size="large" />
              ) : (
                <FacilitiesTable 
                  facilities={filteredFacilities} 
                  scroll={{ x: '100%' }} // Makes table responsive for small screens
                  size="small" // Adjusts table size for better mobile fit
                />
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default FacilitiesComponent;
