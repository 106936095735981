import React, { useEffect, useRef, useState } from "react";
import {
  Descriptions,
  Divider,
  Modal,
  Button,
  Input,
  notification,
  Switch,
  Form,
} from "antd";
import "./facilityDisplayStyles.css";
import EditBasicFacilityDetails from "./EditBasicFacilityDetails";
import UploadFacilityImagesComponent from "./UploadFacilityImages";
import { useSelector, useDispatch } from "react-redux";
import { updateFacility } from "../features/facilities/facilitiesSlice";
import MapView from "../components/MapView";

const DisplayObject = (props) => {
  const roles = useSelector((state) => state.auth.userRoles);
  const email = useSelector((state) => state.auth.userEmail);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableContent, setEditableContent] = useState("");

  const showModal = () => {
    setEditableContent(selectedFacility.notes || "");
    setIsEditMode(false);
    setIsModalVisible(true);
  };

  const toggleEditMode = () => {
    setIsEditMode(true);
  };

  const handleOk = async () => {
    if (!isEditMode) {
      setIsModalVisible(false);
      return;
    }
    const trimmedContent = editableContent.trim();
    try {
      const actionResult = await dispatch(
        updateFacility({
          id: selectedFacility.id,
          data: { facility: { notes: trimmedContent } },
          userRoles: roles,
          userEmail: email,
        })
      );
      if (actionResult.error) {
        console.error("Update error:", actionResult.error);
        throw new Error("Update failed");
      }
      notification.success({
        message: "Success",
        description: "Notes updated successfully!",
      });
      setIsModalVisible(false);
      setIsEditMode(false);
    } catch (error) {
      console.error("Failed to update notes:", error);
      notification.error({
        message: "Error",
        description: `Failed to update notes: ${error.message}`,
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleToggleChange = async (checked) => {
    setLoading(true);
    try {
      const updatedData = {
        facility: {
          custom: {
            enabledImageUpload: checked,
          },
        },
      };

      const actionResult = await dispatch(
        updateFacility({
          id: selectedFacility.id,
          data: updatedData,
          userRoles: roles,
          userEmail: email,
        })
      );

      if (actionResult.error) {
        console.error("Update error:", actionResult.error);
        throw new Error("Update failed");
      }

      notification.success({
        message: "Success",
        description: `Photo upload has been ${checked ? "enabled" : "disabled"} successfully!`,
      });
    } catch (error) {
      console.error("Failed to update facility:", error);
      notification.error({
        message: "Error",
        description: `Failed to update facility: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setEditableContent(event.target.value);
  };

  useEffect(() => {
    setEditableContent(selectedFacility.notes || "");
  }, [selectedFacility.notes]);

  const dayOrder = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const mapRef = useRef(null);

  const {
    access_hours,
    address,
    brand_name,
    city,
    email_address,
    id,
    latitude,
    longitude,
    map,
    name,
    notes,
    office_hours,
    phone_number,
    state,
    timezone,
    website,
    zip_code,
    paymentMethods,
    reit,
    custom,
  } = selectedFacility;

  const emergency_code = custom?.emergencyGateCode;
  const Lcode = custom?.Lcode;

  useEffect(() => {
    if (mapRef.current) return;
    if (latitude === null || longitude === null) return;
  }, [latitude, longitude, selectedFacility]);

  return (
    <div className="facility-display-container">
      <Descriptions
        title="Basic Facility Details"
        extra={
          <Button type="primary">
            <EditBasicFacilityDetails />
          </Button>
        }
        column={{ xs: 1, sm: 2, md: 3 }}
        bordered={false}
      >
        <Descriptions.Item label="Address">{address}</Descriptions.Item>
        <Descriptions.Item label="Map">{map}</Descriptions.Item>
        <Descriptions.Item label="Emergency Code">
          {emergency_code}
        </Descriptions.Item>
        {Lcode && Lcode.length > 0 && (
          <Descriptions.Item label="Lcode">{Lcode}</Descriptions.Item>
        )}
        <Descriptions.Item label="Is REIT?">
          {JSON.stringify(reit)}
        </Descriptions.Item>
        <Descriptions.Item label="Timezone">{timezone}</Descriptions.Item>
        <Descriptions.Item label="Website">{website}</Descriptions.Item>
        <Descriptions.Item label="Brand">{brand_name}</Descriptions.Item>
        <Descriptions.Item label="City">{city}</Descriptions.Item>
        <Descriptions.Item label="Email Address">
          {email_address}
        </Descriptions.Item>
        <Descriptions.Item label="MM ID">{id}</Descriptions.Item>
        <Descriptions.Item label="Latitude">{latitude}</Descriptions.Item>
        <Descriptions.Item label="Longitude">{longitude}</Descriptions.Item>
        <Descriptions.Item label="Name">{name}</Descriptions.Item>
        <Descriptions.Item label="Phone Number">
          {phone_number}
        </Descriptions.Item>
        <Descriptions.Item label="State">{state}</Descriptions.Item>
        <Descriptions.Item label="Zip Code">{zip_code}</Descriptions.Item>
        <Descriptions.Item label="Notes">
          {editableContent ? (
            <a onClick={showModal}>
              {editableContent.length > 20
                ? `${editableContent.substring(0, 20)}...`
                : editableContent}
            </a>
          ) : (
            <a onClick={showModal}>Add notes</a>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Payment Methods">
          {paymentMethods && paymentMethods.length > 0 ? (
            <div>
              {paymentMethods.map((method, index) => (
                <div key={index}>{method.name}</div>
              ))}
            </div>
          ) : (
            "No payment methods available"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Office Hours">
          {office_hours && office_hours.hours ? (
            <div>
              {Object.entries(office_hours.hours)
                .sort((a, b) => dayOrder.indexOf(a[0]) - dayOrder.indexOf(b[0]))
                .map(([day, hours]) => (
                  <div key={day}>
                    {day.charAt(0).toUpperCase() + day.slice(1)}:{" "}
                    {hours.closed
                      ? "Closed"
                      : `${hours.openTime} - ${hours.closeTime}`}
                  </div>
                ))}
            </div>
          ) : (
            "N/A"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Access Hours">
          {access_hours && access_hours.hours ? (
            <div>
              {Object.entries(access_hours.hours)
                .sort((a, b) => dayOrder.indexOf(a[0]) - dayOrder.indexOf(b[0]))
                .map(([day, hours]) => (
                  <div key={day}>
                    {day.charAt(0).toUpperCase() + day.slice(1)}:{" "}
                    {hours.closed
                      ? "Closed"
                      : `${hours.openTime} - ${hours.closeTime}`}
                  </div>
                ))}
            </div>
          ) : (
            "N/A"
          )}
        </Descriptions.Item>
      </Descriptions>

      {(roles.includes("admin") ||
        roles.includes("super_admin") ||
        roles.toLowerCase()?.includes("operator")) && (
        <>
          <Divider>Ops Alignment</Divider>
          <Descriptions column={{ xs: 1, sm: 2, md: 3 }} bordered={false}>
            {email === "Johnnyalambo@gmail.com" ||
              (email === "kperez@nsareit.net" && (
                <>
                  <Descriptions.Item label="NSA #">
                    {selectedFacility.nsa_id}
                  </Descriptions.Item>
                  <Descriptions.Item label="Store Name">
                    {selectedFacility.name}
                  </Descriptions.Item>
                </>
              ))}
            <Descriptions.Item label="Original Store ID">
              {selectedFacility.original_id}
            </Descriptions.Item>
            <Descriptions.Item label="Unit Count">
              {custom?.units}
            </Descriptions.Item>
            <Descriptions.Item label="Commercial Lease Title Holder">
              {custom?.commercial_Lease_Title_Holder}
            </Descriptions.Item>
            <Descriptions.Item label="People Ops Partner">
              {custom?.people_Ops_Partner}
            </Descriptions.Item>
            <Descriptions.Item label="Accounting Partner">
              {custom?.accounting_Partner}
            </Descriptions.Item>
            <Descriptions.Item label="Property Square Footage">
              {custom?.property_square_footage}
            </Descriptions.Item>
            <Descriptions.Item label="County">
              {custom?.county}
            </Descriptions.Item>
            <Descriptions.Item label="Partnership">
              {custom?.partnership}
            </Descriptions.Item>
            <Descriptions.Item label="Acquisition Date">
              {custom?.acquisition_date}
            </Descriptions.Item>
            <Descriptions.Item label="Facilities Coordinator">
              {custom?.facilities_coordinator}
            </Descriptions.Item>
            <Descriptions.Item label="MSA">{custom?.msa}</Descriptions.Item>
            <Descriptions.Item label="Commercial Office Spaces">
              {custom?.commercial_office_spaces}
            </Descriptions.Item>
            <Descriptions.Item label="Billboard on Site">
              {custom?.billboard}
            </Descriptions.Item>
            <Descriptions.Item label="Cell Tower on Site">
              {custom?.cell_tower}
            </Descriptions.Item>
            <Descriptions.Item label="Annex Site">
              {custom?.annexSite}
            </Descriptions.Item>
            <Descriptions.Item label="Site Address">
              {selectedFacility.site_address}
            </Descriptions.Item>
            <Descriptions.Item label="Email Address">
              {selectedFacility.email_address}
            </Descriptions.Item>
            <Descriptions.Item label="Division Geography">
              {custom?.divisionGeography}
            </Descriptions.Item>
            <Descriptions.Item label="Regional Manager">
              {custom?.regionalManager}
            </Descriptions.Item>
            <Descriptions.Item label="Regional Manager Phone Number">
              {custom?.regionalManagerPhoneNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Regional Manager Email">
              {custom?.regionalManagerEmail}
            </Descriptions.Item>
            <Descriptions.Item label="Area Manager">
              {custom?.areaManager}
            </Descriptions.Item>
            <Descriptions.Item label="Area Manager Phone Number">
              {custom?.areaManagerPhoneNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Area Manager Email">
              {custom?.areaManagerEmail}
            </Descriptions.Item>
            <Descriptions.Item label="Area Number">
              {custom?.areaNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Company Code">
              {custom?.companyCode}
            </Descriptions.Item>
            <Descriptions.Item label="Entity Code">
              {custom?.entityCode}
            </Descriptions.Item>
            <Descriptions.Item label="RM Analyst">
              {custom?.rmAnalyst}
            </Descriptions.Item>
            <Descriptions.Item label="Apartment on Site">
              {custom?.apartmentOnSite}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}

      <Modal
        title="Edit Note"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="edit"
            onClick={toggleEditMode}
            style={{ display: isEditMode ? "none" : "inline-block" }}
          >
            Edit
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            style={{ display: isEditMode ? "inline-block" : "none" }}
          >
            Save
          </Button>,
        ]}
      >
        {isEditMode ? (
          <Input.TextArea
            value={editableContent}
            onChange={handleChange}
            rows={4}
          />
        ) : (
          <p>{editableContent}</p>
        )}
      </Modal>

      {!roles.includes("PropertyManager") && (
        <>
          <Divider>Upload Facility Images</Divider>
          <UploadFacilityImagesComponent />

          {(roles.includes("admin") ||
            roles.includes("super_admin") ||
            roles.toLowerCase()?.includes("operator")) && (
            <Form.Item label="Allow Photo Upload:">
              <Switch
                checked={selectedFacility?.custom?.enabledImageUpload}
                onChange={handleToggleChange}
                loading={loading}
              />
            </Form.Item>
          )}
        </>
      )}

      {selectedFacility.latitude && selectedFacility.longitude && (
        <>
          <Divider>Facility Location</Divider>
          <div style={{ marginTop: "20px" }}>
            <MapView facilities={[selectedFacility]} />
          </div>
        </>
      )}
    </div>
  );
};

export default DisplayObject;
