import React, { useEffect, useState } from "react";
import { Descriptions, Divider, Modal, Card } from "antd";
import "./facilityDisplayStyles.css";
import EditDataWarehouseDetails from "./EditDataWarehouseDetails";
import { useSelector } from "react-redux";

const DisplayDataWarehouseDetailsObject = (props) => {
  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );

  const custom_fields = useSelector(
    (state) => state.facilities.selectedFacility.custom
  );
  const roles = useSelector((state) => state.auth.userRoles);
  const email = useSelector((state) => state.auth.userEmail);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const showModal = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const {
    address,
    brand_name,
    city,
    state,
    zip_code,
    latitude,
    longitude,
    name,
    original_id,
    id,
    pms_id,
    reit,
    area,
    region,
    division,
    management_company,
  } = selectedFacility;
  const emergency_code = selectedFacility?.custom?.emergencyGateCode;
  const regionName = selectedFacility.custom
    ? selectedFacility.custom["location/GEONicknames!"]
    : undefined;
  const nsa_id = selectedFacility?.custom?.nsa_id;

  useEffect(() => {}, [custom_fields]);

  return (
    <div className="data-warehouse-container">
      <Card
        title={selectedFacility ? selectedFacility.name : "Loading..."}
        style={{ margin: '16px' }}
      >
        <Descriptions
          title="Data Warehouse Details"
          extra={<EditDataWarehouseDetails />}
          column={{ xs: 1, sm: 2, md: 3 }} // Responsive column configuration
          bordered={false}
        >
          <Descriptions.Item label="Address">{address}</Descriptions.Item>
          <Descriptions.Item label="City">{city}</Descriptions.Item>
          <Descriptions.Item label="State">{state}</Descriptions.Item>
          <Descriptions.Item label="Zip Code">{zip_code}</Descriptions.Item>
          <Descriptions.Item label="Latitude">{latitude}</Descriptions.Item>
          <Descriptions.Item label="Longitude">{longitude}</Descriptions.Item>
          <Descriptions.Item label="Brand">{brand_name}</Descriptions.Item>
          <Descriptions.Item label="Name">{name}</Descriptions.Item>
          <Descriptions.Item label="Original ID">{original_id}</Descriptions.Item>
          <Descriptions.Item label="MM ID">{id}</Descriptions.Item>
          <Descriptions.Item label="PMS Store Id">{pms_id}</Descriptions.Item>
          <Descriptions.Item label="Is REIT?">
            {JSON.stringify(reit)}
          </Descriptions.Item>
          <Descriptions.Item label="Area">{area}</Descriptions.Item>
          <Descriptions.Item label="Region">{region}</Descriptions.Item>
          <Descriptions.Item label="Division">{division}</Descriptions.Item>
          <Descriptions.Item label="NSA ID">{nsa_id}</Descriptions.Item>
          <Descriptions.Item label="Management Company">
            {management_company}
          </Descriptions.Item>
          <Descriptions.Item label="Region Name">{regionName}</Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  );
};

export default DisplayDataWarehouseDetailsObject;
