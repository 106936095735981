import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Spin,
  Card,
  Row,
  Col,
  Select,
  Table,
  Layout,
  Menu,
  Button,
  Drawer,
} from "antd";
import Spinner from "../navigation/Spinner";
import * as facilitiesSlice from "../features/facilities/facilitiesSlice";
import * as engrainSlice from "../features/engrain/engrainSlice";
import * as unitsSlice from "../features/units/unitsSlice";
import MapView from "../components/MapView";
import { MenuOutlined } from "@ant-design/icons";
const { Header, Content, Sider } = Layout;
const { Option } = Select;

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const additionalData =
    useSelector((state) => state.facilities.additionalData) || [];
  const mmFacilities =
    useSelector((state) => state.facilities.facilities) || [];
  const isLoading = useSelector((state) => state.loading.isLoading);

  const [facilityDetailsLoading, setFacilityDetailsLoading] = useState(false);
  const [filteredFacilities, setFilteredFacilities] = useState([]);
  const [hoveredRecord, setHoveredRecord] = useState(null);
  const [mergedData, setMergedData] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const [attributes, setAttributes] = useState({
    area: undefined,
    region: undefined,
    division: undefined,
    city: undefined,
    state: undefined,
    reit: undefined,
    pmsType: undefined,
    brandName: undefined,
    managementCompany: undefined,
    ownership: undefined,
  });
  const [selectOptions, setSelectOptions] = useState({
    areas: [],
    regions: [],
    divisions: [],
    citys: [],
    states: [],
    reits: [],
    pms_types: [],
    brand_names: [],
    management_companys: [],
    ownerships: [],
  });

  useEffect(() => {
    dispatch(facilitiesSlice.updateSelectedFacilityDetails(null));
    dispatch(facilitiesSlice.fetchFacilities());
    dispatch(facilitiesSlice.fetchAdditionalData());
  }, [dispatch]);

  useEffect(() => {
    const mergeData = () => {
      const additionalDataMap = new Map(
        additionalData.map((item) => [item.id, item])
      );
      const merged = mmFacilities.map((item) => {
        const additionalItem = additionalDataMap.get(item.id);
        if (additionalItem) {
          const nonOverlappingProperties = Object.keys(additionalItem).reduce(
            (acc, key) => {
              if (!(key in item)) {
                acc[key] = additionalItem[key];
              }
              return acc;
            },
            {}
          );
          return { ...item, ...nonOverlappingProperties };
        }
        return { ...item };
      });
      setMergedData(merged);
    };

    if (mmFacilities.length && additionalData.length) {
      mergeData();
    }
  }, [mmFacilities, additionalData]);

  useEffect(() => {
    if (mergedData.length) {
      filterFacilities();
    }
  }, [mergedData, attributes]);

  const filterFacilities = () => {
    const filtered = mergedData.filter((facility) => {
      return Object.keys(attributes).every((attr) => {
        if (attributes[attr] === "null") {
          return facility[attr] === null;
        } else if (attributes[attr] === undefined || attributes[attr] === "") {
          return true;
        } else {
          return (
            facility[attr] !== null &&
            facility[attr] !== undefined &&
            facility[attr].toString() === attributes[attr]
          );
        }
      });
    });

    const calcFiltered = filtered.map((cf) => {
      const vacantUnits = cf.units - cf.occupiedUnits;
      const delinquencyRate = (cf.delinquentCount * 100) / cf.occupiedUnits;
      return {
        ...cf,
        vacantUnits,
        delinquencyRate,
      };
    });
    setFilteredFacilities(calcFiltered);
    countFacilityAttributes(calcFiltered);
  };

  const countFacilityAttributes = (facilities) => {
    const counts = Object.keys(selectOptions).reduce((acc, key) => {
      acc[key] = {};
      return acc;
    }, {});
    facilities = facilities.map((facility) => {
      let updatedFacility = { ...facility };
      Object.keys(updatedFacility).forEach((key) => {
        if (updatedFacility[key] === true) {
          updatedFacility[key] = "true";
        } else if (updatedFacility[key] === false) {
          updatedFacility[key] = "false";
        } else if (updatedFacility[key] === null) {
          updatedFacility[key] = "null";
        } else if (updatedFacility[key] === undefined) {
          updatedFacility[key] = "undefined";
        }
      });
      return updatedFacility;
    });

    Object.keys(counts).forEach((pluralKey) => {
      facilities.forEach((facility) => {
        let attribute = pluralKey.slice(0, -1);
        if (facility[attribute] != null) {
          counts[pluralKey][facility[attribute]] =
            (counts[pluralKey][facility[attribute]] || 0) + 1;
        }
      });
    });

    const newSelectOptions = Object.keys(counts).reduce((acc, key) => {
      acc[key] = Object.keys(counts[key]).map((attrKey) => ({
        key: attrKey,
        value: counts[key][attrKey],
      }));
      return acc;
    }, {});
    setSelectOptions(newSelectOptions);
  };

  const handleAttributeChange = (attribute, value) => {
    setAttributes((prev) => ({ ...prev, [attribute]: value }));
  };

  const renderSelects = (group) => {
    return group.map((attribute) => (
      <Menu.Item key={attribute}>
        <Select
          onChange={(value) => handleAttributeChange(attribute, value)}
          allowClear
          placeholder={`${attribute.charAt(0).toUpperCase() + attribute.slice(1)} (${selectOptions[`${attribute}s`] ? selectOptions[`${attribute}s`].length : 0})`}
          style={{ width: "100%", marginBottom: 8 }}
        >
          {selectOptions[`${attribute}s`]
            ? selectOptions[`${attribute}s`].map((option) => (
                <Option key={option.key} value={option.key}>
                  {`${attribute.charAt(0).toUpperCase() + attribute.slice(1)}: ${option.key} (${option.value})`}
                </Option>
              ))
            : null}
        </Select>
      </Menu.Item>
    ));
  };

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const calculateData = (data) => {
    let totalOccupancyRate = 0,
      totalMonthlyOpportunity = 0,
      totalMonthlyRevenue = 0,
      totalDelinquencyRate = 0;
    data.forEach((item) => {
      const occupancyRate = parseFloat(item.occupancy_rate || 0);
      const delinquencyRate = parseFloat(item.delinquencyRate || 0);
      const monthlyOpportunity = parseFloat(item.monthly_opportunity || 0);
      const monthlyRevenue = parseFloat(item.monthly_revenue || 0);
      if (!isNaN(occupancyRate)) {
        totalOccupancyRate += occupancyRate;
      }
      if (!isNaN(monthlyOpportunity)) {
        totalMonthlyOpportunity += monthlyOpportunity;
      }
      if (!isNaN(delinquencyRate)) {
        totalDelinquencyRate += delinquencyRate;
      }
      if (!isNaN(monthlyRevenue)) {
        totalMonthlyRevenue += monthlyRevenue;
      }
    });
    const averageOccupancyRate =
      data.length > 0 ? (totalOccupancyRate / data.length).toFixed(2) : "0.00";
    const formattedTotalMonthlyOpportunity =
      totalMonthlyOpportunity.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    const formattedDelinquencyRate =
      data.length > 0
        ? (totalDelinquencyRate / data.length).toFixed(2)
        : "0.00";
    const formattedTotalMonthlyRevenue = totalMonthlyRevenue.toLocaleString(
      "en-US",
      { style: "currency", currency: "USD" }
    );
    return {
      averageOccupancyRate,
      formattedTotalMonthlyOpportunity,
      formattedTotalMonthlyRevenue,
      formattedDelinquencyRate,
    };
  };

  const columns = [
    {
      title: "Facility",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      responsive: ["md"],
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      sorter: (a, b) => a.units - b.units,
      responsive: ["md"],
    },
    {
      title: "Occupied",
      dataIndex: "occupiedUnits",
      key: "occupiedUnits",
      sorter: (a, b) => a.occupiedUnits - b.occupiedUnits,
      responsive: ["md"],
    },
    {
      title: "Vacant",
      dataIndex: "vacantUnits",
      key: "vacantUnits",
      sorter: (a, b) => a.vacantUnits - b.vacantUnits,
      responsive: ["md"],
    },
    {
      title: "Delinquent",
      dataIndex: "delinquentCount",
      key: "delinquentCount",
      sorter: (a, b) => a.delinquentCount - b.delinquentCount,
      responsive: ["md"],
    },
    {
      title: "Delinquency",
      dataIndex: "delinquencyRate",
      key: "delinquencyRate",
      sorter: (a, b) => a.delinquencyRate - b.delinquencyRate,
      render: (text) => `${parseFloat(text).toFixed(2)}%`,
    },
    {
      title: "Occupancy",
      dataIndex: "occupancy_rate",
      key: "occupancy_rate",
      sorter: (a, b) => a.occupancy_rate - b.occupancy_rate,
      render: (text) => `${parseFloat(text).toFixed(2)}%`,
    },
    {
      title: "Revenue",
      dataIndex: "monthly_revenue",
      key: "monthly_revenue",
      sorter: (a, b) => a.monthly_revenue - b.monthly_revenue,
      render: (text) =>
        `${parseFloat(text).toLocaleString("en-US", { style: "currency", currency: "USD" })}`,
    },
    {
      title: "Opportunity",
      dataIndex: "monthly_opportunity",
      key: "monthly_opportunity",
      sorter: (a, b) => a.monthly_opportunity - b.monthly_opportunity,
      render: (text) =>
        `${parseFloat(text).toLocaleString("en-US", { style: "currency", currency: "USD" })}`,
    },
  ];

  const handleView = async (record) => {
    setFacilityDetailsLoading(true);
    try {
      const facilityDetailsResponse = await dispatch(
        facilitiesSlice.fetchFacilityDetails({
          brand: record.brand,
          id: record.id,
        })
      ).unwrap();
      dispatch(
        facilitiesSlice.updateSelectedFacilityDetails(facilityDetailsResponse)
      );
      await dispatch(engrainSlice.fetchUnits());
      await dispatch(engrainSlice.fetchFloors());
      await dispatch(unitsSlice.fetchUnits(record.id));
      setFacilityDetailsLoading(false);
      navigate(`/facilities/${record.id}`);
    } catch (error) {
      console.error("Error fetching facility details:", error);
      setFacilityDetailsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner size="large" />;
  }

  const {
    averageOccupancyRate,
    formattedTotalMonthlyOpportunity,
    formattedTotalMonthlyRevenue,
    formattedDelinquencyRate,
  } = calculateData(filteredFacilities);

  return (
    <Layout style={{ width: "100%" }}>
      <Row align="middle" gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col>
          <Button type="primary" icon={<MenuOutlined />} onClick={showDrawer}>
            Filters
          </Button>
        </Col>
        <Col flex="auto">
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {Object.keys(attributes).map(
              (key) =>
                attributes[key] && (
                  <span
                    key={key}
                    style={{
                      background: "#f0f0f0",
                      padding: "4px 8px",
                      borderRadius: "4px",
                    }}
                  >
                    {`${key.charAt(0).toUpperCase() + key.slice(1)}: ${attributes[key]}`}
                  </span>
                )
            )}
          </div>
        </Col>
      </Row>
      <Drawer
        title="Filter Facilities"
        placement="left"
        onClose={closeDrawer}
        visible={isDrawerVisible}
        width={300}
      >
        <Menu mode="inline" style={{ borderRight: 0 }}>
          <Menu.ItemGroup title="Division, Region, Area">
            {renderSelects(["division", "region", "area"])}
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Reit, Ownership, Management">
            {renderSelects(["reit", "ownership", "management_company"])}
          </Menu.ItemGroup>
          <Menu.ItemGroup title="PMS, Brand">
            {renderSelects(["pms_type", "brand_name"])}
          </Menu.ItemGroup>
          <Menu.ItemGroup title="State, City">
            {renderSelects(["state", "city"])}
          </Menu.ItemGroup>
        </Menu>
      </Drawer>

      <Layout style={{ padding: "0 15px 15px" }}>
        <Card>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}>
              <Card title="Occupancy Rate">{averageOccupancyRate}%</Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card title="Delinquency Rate">{formattedDelinquencyRate}%</Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card title="Monthly Revenue">
                {formattedTotalMonthlyRevenue}
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card title="Monthly Opportunity">
                {formattedTotalMonthlyOpportunity}
              </Card>
            </Col>
          </Row>
          <MapView
            facilities={filteredFacilities}
            hoveredRecord={hoveredRecord}
          />
          <Table
            dataSource={filteredFacilities}
            columns={columns}
            rowKey="id"
            onRow={(record, rowIndex) => {
              return {
                onMouseEnter: () => setHoveredRecord(record),
                onMouseLeave: () => setHoveredRecord(null),
                onClick: () => handleView(record),
              };
            }}
            scroll={{ x: 800 }}
          />
        </Card>
      </Layout>
    </Layout>
  );
};

export default Home;
