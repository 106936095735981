import React, { useEffect, useState } from "react";
import {
  Descriptions,
  Divider,
  Card,
  Steps,
  Button,
  Form,
  Input,
  Radio,
  DatePicker,
  notification,
  Spin,
  Upload,
  message,
} from "antd";
import "./facilityDisplayStyles.css";
import { useSelector } from "react-redux";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
const { Step } = Steps;

// Function to normalize file input
const normFile = (e) => {
  console.log("Upload event:", e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const SurveyComponent = () => {
  const microMind = process.env.REACT_APP_MICRO_API_URL;

  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );

  const userRole = useSelector((state) => state.auth.userRoles);
  const mmApiKey = useSelector((state) => state.auth.mmApiKey);

  // Initial steps with detailed questions
  const initialSteps = [
    {
      title: "Step 1",
      questions: [
        {
          questionText:
            "Please search for the location in Google when preparing to drive, and click the 'get directions' in the GBP listing. Do the directions bring you to the property and to the right part of the property?",
        },
      ],
    },
    {
      title: "Step 2",
      questions: [
        { questionText: "Were the office hours accurate?" },
        {
          questionText:
            "Was the office closed when it was supposed to be open?",
        },
        { questionText: "What sign was hung if closed, and was that correct?" },
      ],
    },
    {
      title: "Step 3",
      questions: [
        {
          questionText:
            "Is the parking lot, entrance, landscaping, trash enclosure clean and well kept?",
        },
      ],
    },
    {
      title: "Step 4",
      questions: [
        {
          questionText:
            "Does the first look on the front door and window meet NSA's brand expectations?",
        },
        {
          questionText: "Is only the accurate store hour decal hanging?",
        },
        {
          questionText:
            "When relevant, is the two-sided clock/sorry we missed you sign hanging?",
        },
      ],
    },
  ];

  // Additional steps to ask if the user was able to enter the property
  const additionalSteps = [
    {
      title: "Step 6",
      questions: [
        {
          questionText:
            "Is the overall store signage visible, lit (where applicable), and in good condition?",
        },
      ],
    },
    {
      title: "Step 7",
      questions: [
        {
          questionText:
            "Is the office clean, organized, clutter-free, and without personal items?",
        },
        {
          questionText: "Is the restroom clean and stocked?",
        },
      ],
    },
    {
      title: "Step 8",
      questions: [
        {
          questionText:
            "Is the merchandise properly arranged, stocked, signed, and clean?",
        },
      ],
    },
    {
      title: "Step 9",
      questions: [
        {
          questionText:
            "Are drive aisles and hallways clean and free of debris (doors, walls, floors clean)?",
        },
        {
          questionText:
            "Are interior hallway lights operating, and are exterior lights on when applicable?",
        },
      ],
    },
    {
      title: "Step 10",
      questions: [
        {
          questionText: "Are egress and emergency access points operational?",
        },
      ],
    },
    {
      title: "Step 11",
      questions: [
        {
          questionText:
            "Was the interaction with our store employee a positive one?",
        },
        {
          questionText:
            "Was their uniform presentation up to expectations (wearing appropriate shirt, clean, and presentable)?",
        },
        {
          questionText:
            "Were they upbeat, professional, and did you have an overall positive interaction?",
        },
      ],
    },
  ];


  // Function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months start at 0!
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  // Function to get current time in HH:MM format
  const getCurrentTime = () => {
    const now = new Date();
    const hh = String(now.getHours()).padStart(2, '0');
    const mm = String(now.getMinutes()).padStart(2, '0');
    return `${hh}:${mm}`;
  };

  // Function to reset the survey
  const retakeSurvey = () => {
    form.resetFields();
    setFormData({});
    setCurrent(0);
    initializeSteps();
  };

  // Function to get the completion step
  const getCompletionStep = () => ({
    title: "Completion",
    key: "completion",
    content: (
      <>
        <h2>Thank you for completing the survey!</h2>
        <Button type="primary" onClick={retakeSurvey}>
          Retake Survey
        </Button>
      </>
    ),
  });

  // Function to initialize steps
  const initializeSteps = () => {
    let overallQuestionIndex = 0;

    // Process initial steps
    const processedSteps = initialSteps.map((step, stepIndex) => {
      const content = (
        <>
          {step.questions.map((question, questionIndex) => {
            overallQuestionIndex++;
            const radioFieldName = `question_${overallQuestionIndex}_answer`;
            const textFieldName = `question_${overallQuestionIndex}_text`;

            return (
              <div key={`${stepIndex}_${questionIndex}`}>
                {/* Question Title */}
                <Form.Item>
                  <strong>{question.questionText}</strong>
                </Form.Item>

                {/* Yes/No Radio Button */}
                <Form.Item
                  name={radioFieldName}
                  rules={[
                    { required: true, message: "Please select Yes or No" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* Conditional Text Box */}
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues[radioFieldName] !== currentValues[radioFieldName]
                  }
                >
                  {({ getFieldValue }) => {
                    const answer = getFieldValue(radioFieldName);
                    if (answer === "Yes") {
                      // Optional text box when 'Yes' is selected
                      return (
                        <Form.Item
                          name={textFieldName}
                          label="Additional Comments"
                        >
                          <Input.TextArea
                            rows={3}
                            placeholder="Optional comments..."
                          />
                        </Form.Item>
                      );
                    } else if (answer === "No") {
                      // Required text box when 'No' is selected
                      return (
                        <Form.Item
                          name={textFieldName}
                          label="Please explain"
                          rules={[
                            {
                              required: true,
                              message:
                                "This field is required when you select No",
                            },
                          ]}
                        >
                          <Input.TextArea
                            rows={3}
                            placeholder="Required comments..."
                          />
                        </Form.Item>
                      );
                    }
                    return null;
                  }}
                </Form.Item>

                <Divider />
              </div>
            );
          })}
        </>
      );

      return {
        title: step.title,
        key: `initialStep_${stepIndex + 1}`,
        content: content,
      };
    });

    // Add the entry question as the next step
    overallQuestionIndex++;
    const entryStep = {
      title: `Entry Question`,
      key: "entryQuestion",
      content: (
        <>
          <Form.Item
            name="entryAnswer"
            label="Were you able to enter the property?"
            rules={[{ required: true, message: "Please select Yes or No" }]}
          >
            <Radio.Group>
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      ),
    };

    // Initialize steps array
    let allSteps = [...processedSteps, entryStep];

    // Replace the DatePicker with separate date and time inputs
  const finalDetailsStep = {
    title: `Final Details`,
    key: "finalDetails",
    content: (
      <>
        <Form.Item
          name="surveyorName"
          label="Your Name"
          rules={[{ required: true, message: "Please enter your name" }]}
        >
          <Input placeholder="Enter your name" style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          name="visitDate"
          label="Date of Visit"
          rules={[{ required: true, message: "Please select date of visit" }]}
        >
          <Input
            type="date"
            style={{ width: "100%" }}
            placeholder="Select date"
            max={getTodayDate()} // Disable future dates
          />
        </Form.Item>

        <Form.Item
          name="visitTime"
          label="Time of Visit"
          rules={[{ required: true, message: "Please select time of visit" }]}
        >
          <Input
            type="time"
            style={{ width: "100%" }}
            placeholder="Select time"
            max={getCurrentTime()} // Disable future times (if same day)
          />
        </Form.Item>

        <Form.Item
          name="storeClosedDuringVisit"
          label="Was the store closed during your visit?"
          rules={[{ required: true, message: "Please select Yes or No" }]}
        >
          <Radio.Group>
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
          </Radio.Group>
        </Form.Item>

        {/* Conditional Photo Upload for AreaManager */}
        {true && (
          <Form.Item
            name="managerPhoto"
            label="Please take a clear photo of Property Manager's entire computer screen - verifying only Company Approved Platforms are in use"
            rules={[{ required: true, message: "Please upload the required photo" }]}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="photo"
              listType="picture-card"
              beforeUpload={() => false} // Prevent automatic upload
              accept="image/*"
            >
              {form.getFieldValue("managerPhoto")?.length >= 1 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        )}
      </>
    ),
  };
    // jeffs version
    // const finalDetailsStep = {
    //   title: `Final Details`,
    //   key: "finalDetails",
    //   content: (
    //     <>
    //       <Form.Item
    //         name="surveyorName"
    //         label="Your Name"
    //         rules={[{ required: true, message: "Please enter your name" }]}
    //       >
    //         <Input placeholder="Enter your name" />
    //       </Form.Item>
    //       <Form.Item
    //         name="visitDateTime"
    //         label="Date and Time of Visit"
    //         rules={[
    //           {
    //             required: true,
    //             message: "Please select date and time of visit",
    //           },
    //         ]}
    //       >
    //         <DatePicker
    //           showTime
    //           format="YYYY-MM-DD HH:mm"
    //           style={{ width: "100%" }}
    //           disabledDate={(current) =>
    //             current && current > moment().endOf("day")
    //           }
    //         />
    //       </Form.Item>
    //       <Form.Item
    //         name="storeClosedDuringVisit"
    //         label="Was the store closed during your visit?"
    //         rules={[{ required: true, message: "Please select Yes or No" }]}
    //       >
    //         <Radio.Group>
    //           <Radio value="Yes">Yes</Radio>
    //           <Radio value="No">No</Radio>
    //         </Radio.Group>
    //       </Form.Item>

    //       {/* Conditional Photo Upload for AreaManager */}
    //       {true && (
    //         <Form.Item
    //           name="managerPhoto"
    //           label="Please take a clear photo of Property Manager's entire computer screen - verifying only Company Approved Platforms are in use"
    //           rules={[
    //             { required: true, message: "Please upload the required photo" },
    //           ]}
    //           valuePropName="fileList"
    //           getValueFromEvent={normFile}
    //         >
    //           <Upload
    //             name="photo"
    //             listType="picture-card"
    //             beforeUpload={() => false} // Prevent automatic upload
    //             accept="image/*"
    //           >
    //             {form.getFieldValue("managerPhoto")?.length >= 1 ? null : (
    //               <div>
    //                 <PlusOutlined />
    //                 <div style={{ marginTop: 8 }}>Upload</div>
    //               </div>
    //             )}
    //           </Upload>
    //         </Form.Item>
    //       )}
    //     </>
    //   ),
    // };

    // Set the steps including the completion step
    setSteps([...allSteps, finalDetailsStep, getCompletionStep()]);
    
  };

  // Initialize steps on component mount
  useEffect(() => {
    initializeSteps();
  }, []);

  // HTTP submission function using fetch
  // HTTP submission function using fetch
  const submitSurveyData = async (data) => {
    try {
      // Create a FormData object to handle file uploads
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === "managerPhoto" && data[key]) {
          // Append the photo file
          formData.append(key, data[key]);
        } else {
          // Append other form data
          formData.append(key, data[key]);
        }
      });

      // Make a POST request using fetch with headers and credentials
      const microMind = process.env.REACT_APP_MICRO_API_URL;
      const microMindKey = process.env.REACT_APP_MICRO_KEY;
      const response = await fetch(`${microMind}/api/submit-survey`, {
        method: "POST",
        body: formData,
        headers: {
          // This content type is automatically set by the browser for FormData; no need to set it manually
          "x-api-key": microMindKey,
          Authorization: `Bearer ${mmApiKey}`,
        },
        credentials: "same-origin", // Include credentials for same-origin requests
      });

      // Check for HTTP errors
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to submit survey. Please try again."
        );
      }

      // Parse and return the JSON response
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error submitting survey data:", error);
      throw new Error(
        error.message || "Failed to submit survey. Please try again."
      );
    }
  };

  const next = () => {
    form
      .validateFields()
      .then((values) => {
        const updatedFormData = { ...formData, ...values };
        setFormData(updatedFormData);

        // Check if we're at the entry question step
        if (steps[current]?.key === "entryQuestion") {
          const entryAnswer = values.entryAnswer;
          if (entryAnswer === "Yes") {
            // User was able to enter, add additional steps
            let overallQuestionIndex = Object.keys(updatedFormData).filter(
              (key) => key.includes("question_")
            ).length;

            const additionalProcessedSteps = additionalSteps.map(
              (step, index) => {
                const content = (
                  <>
                    {step.questions.map((question, questionIndex) => {
                      overallQuestionIndex++;
                      const radioFieldName = `question_${overallQuestionIndex}_answer`;
                      const textFieldName = `question_${overallQuestionIndex}_text`;

                      return (
                        <div key={`additional_${index}_${questionIndex}`}>
                          {/* Question Title */}
                          <Form.Item>
                            <strong>{question.questionText}</strong>
                          </Form.Item>

                          {/* Yes/No Radio Button */}
                          <Form.Item
                            name={radioFieldName}
                            rules={[
                              {
                                required: true,
                                message: "Please select Yes or No",
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>

                          {/* Conditional Text Box */}
                          <Form.Item
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues[radioFieldName] !==
                              currentValues[radioFieldName]
                            }
                          >
                            {({ getFieldValue }) => {
                              const answer = getFieldValue(radioFieldName);
                              if (answer === "Yes") {
                                // Optional text box when 'Yes' is selected
                                return (
                                  <Form.Item
                                    name={textFieldName}
                                    label="Additional Comments"
                                  >
                                    <Input.TextArea
                                      rows={3}
                                      placeholder="Optional comments..."
                                    />
                                  </Form.Item>
                                );
                              } else if (answer === "No") {
                                // Required text box when 'No' is selected
                                return (
                                  <Form.Item
                                    name={textFieldName}
                                    label="Please explain"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "This field is required when you select No",
                                      },
                                    ]}
                                  >
                                    <Input.TextArea
                                      rows={3}
                                      placeholder="Required comments..."
                                    />
                                  </Form.Item>
                                );
                              }
                              return null;
                            }}
                          </Form.Item>

                          <Divider />
                        </div>
                      );
                    })}
                  </>
                );

                return {
                  title: `Step ${steps.length + index}`,
                  key: `additionalStep_${index + 1}`,
                  content: content,
                };
              }
            );

            // Update steps to include additional steps and completion step
            setSteps((prevSteps) => {
              // Remove the final details and completion steps
              const stepsWithoutLast = prevSteps.slice(0, current + 1);
              return [
                ...stepsWithoutLast,
                ...additionalProcessedSteps,
                prevSteps[prevSteps.length - 2], // Final Details Step
                prevSteps[prevSteps.length - 1], // Completion Step
              ];
            });

            setCurrent(current + 1);
            form.resetFields();
          } else {
            // User was not able to enter, proceed to final details step
            const finalDetailsIndex = steps.findIndex(
              (step) => step.key === "finalDetails"
            );
            if (finalDetailsIndex !== -1) {
              setCurrent(finalDetailsIndex);
            }
            form.resetFields();
          }
        } else if (steps[current]?.key === "finalDetails") {
          // Last step before completion (Final Details), submit survey
          onFinish(updatedFormData);
        } else {
          // Proceed normally
          setCurrent(current + 1);
          form.resetFields();
        }
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
    form.setFieldsValue(formData); // Restore form data for the previous step
  };

  const onFinish = (finalData = formData) => {
    form
      .validateFields()
      .then((values) => {
        // Combine date and time into a single datetime if needed
        let visitDateTime = null;
        if (values.visitDate && values.visitTime) {
          visitDateTime = new Date(`${values.visitDate}T${values.visitTime}`);
        }
       
        // Call the mock submission function
        // Assuming `facilityId` comes from a prop or is available in the state
        const facilityId = selectedFacility?.id || "default-facility-id"; // Replace this with how you get the facility ID

        const mergedData = { ...finalData, ...values, visitDateTime,facilityId }; // Include facilityId in the merged data
        console.log("Payload to be submitted:", mergedData);

        // Handle image file if present
        if (mergedData.managerPhoto) {
          const file = mergedData.managerPhoto[0].originFileObj;
          // Replace the `managerPhoto` field with the file object
          mergedData.managerPhoto = file;
        }

        setLoading(true);

        // Call the submission function
        submitSurveyData(mergedData)
          .then((response) => {
            // Handle success
            notification.success({
              message: "Success",
              description: response.message,
            });

            setCurrent(steps.length - 1);

            // Reset loading
            setLoading(false);
          })
          .catch((error) => {
            // Handle failure
            notification.error({
              message: "Error",
              description: error.message,
            });

            // Stay on the same step to allow retry
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

   // Ensure steps are initialized before rendering
   if (steps.length === 0) {
    return (
      <div style={{ textAlign: "center", marginTop: 50 }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: "0 16px" }}>
      <Steps current={current} responsive={true}>
        {steps.map((item) => (
          <Step key={item.key} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content" style={{ marginTop: 24 }}>
        <Form form={form} layout="vertical">
          {steps[current] && steps[current].content}
        </Form>
      </div>
      <div className="steps-action" style={{ marginTop: 24 }}>
        {current > 0 && current < steps.length - 1 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {steps[current] &&
          steps[current].key !== "completion" &&
          steps[current].key !== "finalDetails" && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
        {steps[current] && steps[current].key === "finalDetails" && (
          <Button type="primary" onClick={() => next()} loading={loading}>
            Submit
          </Button>
        )}
      </div>
    </div>
  );

};

const StoreAuditDisplay = (props) => {
  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );

  // Destructure required properties from selectedFacility
  const { address, name } = selectedFacility || {};

  return (
    <>
      <Card title={name || "Loading..."}>
        <Descriptions title="Store Audit" column={3} bordered={false}>
          <Descriptions.Item label="Address">{address}</Descriptions.Item>
          {/* Add other Descriptions.Items as needed */}
        </Descriptions>

        <Divider />

        {/* Include the Survey Component */}
        <SurveyComponent />
      </Card>
    </>
  );
};

export default StoreAuditDisplay;
