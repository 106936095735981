export async function sendNotification({
  facility,
  state,
  facility_id,
  microMind,
  microMindKey,
  mmApiKey,
  data
}) {
  // Extract email recipients, filtering out undefined or null values
  const emailRecipients = [
    facility.custom?.areaManagerEmail,
    facility.custom?.regionalManagerEmail,
    "kperez@nsareit.net",
    // "jswanson@nsareit.net"
    // Add more recipients as needed
  ].filter((email) => email);

  const userEmail = state.auth.userEmail;

  // Conditional assignment of notificationFacility
  const notificationFacility = state.facilities.selectedFacility
    ? state.facilities.selectedFacility
    : facility;

  try {
    const notifyResponse = await fetch(`${microMind}/api/notify`, {
      method: "POST",
      body: JSON.stringify({
        subject: `${notificationFacility.name} Status Updated`,
        message: `Facility details for ${notificationFacility.name} with MM id ${facility_id} have been updated by ${userEmail}. Please review the changes.`,
        recipients: emailRecipients,
        user: userEmail, // Send the user's email as well
        possibleChanges: JSON.stringify(data),
        previousData: null
      }),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": microMindKey,
        Authorization: `Bearer ${mmApiKey}`,
      },
      credentials: "same-origin",
    });

    // Additional processing if the user is a specific email
    if (userEmail === "jswanson@nsareit.net") {
      const notifyComplete = await notifyResponse.text();
      console.log("Notification Response: ", notifyComplete);
    }

    // Return the response or any relevant data
    return await notifyResponse.json();
  } catch (error) {
    console.error("Error sending notification:", error);
    throw error; // Re-throw the error to handle it further up the chain
  }
}
