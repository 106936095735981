import React, { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  Spin,
  notification,
  Space,
  Row,
  Col,
  Divider
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { updateFacility } from "../features/facilities/facilitiesSlice";
import store from "../redux/store";
const EditCustomFacilityDetails = () => {
  const roles = useSelector((state) => state.auth.userRoles);
  const email = useSelector((state) => state.auth.userEmail);

  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );
  const custom_fields = useSelector(
    (state) => state.facilities.selectedFacility.custom
  );

  useEffect(() => {}, [selectedFacility, custom_fields]);

  const { loading } = useSelector((state) => state.facilities);

  const [form] = Form.useForm();
  // const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const openNotification = (type, message) => {
    notification[type]({
      message: type === "success" ? "Success" : "Error",
      description: message,
      placement: "topRight",
    });
  };

  const transformPayload = (values) => {
    return {
      facility: {
        custom: {
          ...values,
        },
      },
    };
  };

  const onFinish = async (values) => {
    // setLoading(true);
    try {
      const transformedValues = transformPayload(values);
      const actionResult = await store.dispatch(
        updateFacility({
          id: selectedFacility.id,
          data: transformedValues,
          userRoles: roles,
          userEmail: email
        })
      );

      if (updateFacility.fulfilled.match(actionResult)) {
        openNotification(
          "success",
          "This facility has been successfully updated!"
        );
        onClose();
      } else {
        openNotification(
          "error",
          "There was an issue updating this facility. Please try again."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      openNotification(
        "error",
        "There was an issue updating this facility. Please try again."
      );
    }
    // finally {
    // setLoading(false);
    // }
  };

  const isSpecialEmail = (email) => {
    const specialEmails = [
      'JEsbenshade@nsareit.net',
      'agoris@nsareit.net',
      'mhall@nsabrands.com',
      'CWess@nsabrands.com',
      'kperez@nsareit.net',
      'Johnnyalambo@gmail.com',
      'jswanson@nsareit.net'
    ];
    return specialEmails.includes(email);
  };
// Custom Details has fields in an unorganized pattern - can we group Area/Region/Division, AM/AM Email/AM Phone #, RM/RM Email/RM Phone #, Director all together ?
return (
  <>
    {/* Conditionally render the "Edit Custom Details" button */}
    <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
      Edit Custom Details
    </Button>

    {/* Drawer Component for Editing Custom Facility Details */}
    <Drawer
      title="Edit Custom Facility Details"
      width={720}
      onClose={onClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={() => form.submit()}>
            Submit
          </Button>
        </Space>
      }
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          form={form}
          initialValues={custom_fields}
        >
          {/* Area/Region/Division Details */}
          <Divider orientation="left">Area/Region/Division Details</Divider>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="areaNumber" label="Area Number">
                <Input placeholder="Enter Area Number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="region" label="Region">
                <Input placeholder="Enter Region" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="divisionNumber" label="Division Number">
                <Input placeholder="Enter Division Number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="regionNumber" label="Region Number">
                <Input placeholder="Enter Region Number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="divisionGeography" label="Division Geography">
                <Input placeholder="Enter Division Geography" />
              </Form.Item>
            </Col>
          </Row>

          {/* Area Manager (AM) Details */}
          <Divider orientation="left">Area Manager (AM) Details</Divider>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="areaManager" label="Area Manager">
                <Input placeholder="Enter Area Manager" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="areaManagerEmail" label="Area Manager Email">
                <Input placeholder="Enter Area Manager Email" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="areaManagerPhoneNumber" label="Area Manager Phone Number">
                <Input placeholder="Enter Area Manager Phone Number" />
              </Form.Item>
            </Col>
          </Row>

          {/* Regional Manager (RM) Details */}
          <Divider orientation="left">Regional Manager (RM) Details</Divider>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="regionalManager" label="Regional Manager">
                <Input placeholder="Enter Regional Manager" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="regionalManagerEmail" label="Regional Manager Email">
                <Input placeholder="Enter Regional Manager Email" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="regionalManagerPhoneNumber" label="Regional Manager Phone Number">
                <Input placeholder="Enter Regional Manager Phone Number" />
              </Form.Item>
            </Col>
          </Row>

             {/* City Manager (RM) Details */}
             <Divider orientation="left">City Manager (CM) Details</Divider>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="cityManager" label="City Manager">
                <Input placeholder="Enter City Manager" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="cityManagerEmail" label="City Manager Email">
                <Input placeholder="Enter City Manager Email" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="cityManagerPhoneNumber" label="City Manager Phone Number">
                <Input placeholder="Enter City Manager Phone Number" />
              </Form.Item>
            </Col>
          </Row>

          {/* Director Details */}
          <Divider orientation="left">Director Details</Divider>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="divisionDirector" label="Division Director">
                <Input placeholder="Enter Division Director" />
              </Form.Item>
            </Col>
          </Row>

          {/* Other Custom Fields */}
          <Divider orientation="left">Other Custom Details</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="pro" label="Pro">
                <Input placeholder="Enter Pro" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="annexSite" label="Annex Site">
                <Input placeholder="Enter Annex Site" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="landmarks" label="Landmarks">
                <Input placeholder="Enter Landmarks" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="rmAnalyst" label="RM Analyst">
                <Input placeholder="Enter RM Analyst" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="gateSystem" label="Gate System">
                <Input placeholder="Enter Gate System" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="companyCode" label="Company Code">
                <Input placeholder="Enter Company Code" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="demographics" label="Demographics">
                <Input placeholder="Enter Demographics" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="onSiteManager" label="On Site Manager">
                <Input placeholder="Enter On Site Manager" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="nsaStoreNumber" label="NSA Store Number">
                <Input placeholder="Enter NSA Store Number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="annexSiteNumber" label="Annex Site Number">
                <Input placeholder="Enter Annex Site Number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="apartmentOnSite" label="Apartment On Site">
                <Input placeholder="Enter Apartment On Site" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="gateSystemNotes" label="Gate System Notes">
                <Input placeholder="Enter Gate System Notes" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="boatRvSemiTruckWineOther"
                label="Boat/RV/Semi-Truck/Wine/Other"
              >
                <Input placeholder="Enter Boat/RV/Semi-Truck/Wine/Other" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="propertyManagementSystem"
                label="Property Management System"
              >
                <Input placeholder="Enter Property Management System" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="specialHoursForBusinessTenantsOther"
                label="Special Hours For Business Tenants/Other"
              >
                <Input placeholder="Enter Special Hours For Business Tenants/Other" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="anythingUniqueAboutThisFacilityOrItsOfferings"
                label="Anything Unique About This Facility Or Its Offerings"
              >
                <Input.TextArea
                  placeholder="Enter Anything Unique About This Facility Or Its Offerings"
                  autoSize={{ minRows: 3, maxRows: 6 }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  </>
);
};

export default EditCustomFacilityDetails;
